import { render, staticRenderFns } from "./adddetail.vue?vue&type=template&id=8d0d57a0&scoped=true"
import script from "./adddetail.vue?vue&type=script&lang=js"
export * from "./adddetail.vue?vue&type=script&lang=js"
import style0 from "./adddetail.vue?vue&type=style&index=0&id=8d0d57a0&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d0d57a0",
  null
  
)

export default component.exports