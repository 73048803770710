<!--评价列表  -->
<template>
  <div>
    <ax-table
      ref="roleTable"
      :show-search="true"
      :searchForm="searchForm"
      :searchActions="searchActions"
      :columns="columns"
      :dataSourceApi="api.list"
      :dataSourceParams="dataSourceParams"
      :clickable="false"
      :showToolBar="false"
      @action-column-click="actionColumnClick"
      :scroll="{ y: '55vh', x: '80vw' }"
    >
      <div slot="isSatisfied" slot-scope="{ record }">
        <a-button
          :class="{
            green: record.isSatisfied === '0',
            grey: record.isSatisfied === '1',
          }"
        >
          {{ record.isSatisfied === "0" ? "满意" : "不满意" }}
        </a-button>
      </div>
    </ax-table>
    <adddetail ref="detail" @refreshTable="refreshTable"></adddetail>
  </div>
</template>
<script>
import api from "./api";
import axTable from "@/components/ax-table";
import adddetail from "./adddetail.vue";

const state = [
  { label: "满意", value: "0" },
  { label: "不满意", value: "1" },
];

const searchForm = [
  {
    label: "关键字",
    type: "inputSearch",
    model: "propertyName",
    options: { placeholder: "请输入物业名称" },
    formItem: {},
    col: { span: 6 },
  },

  {
    label: "评价人",
    type: "inputSearch",
    model: "residentName",
    options: { placeholder: "请输入" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "是否满意",
    type: "select",
    model: "isSatisfied",
    options: { options: state, placeholder: "请选择" },
    formItem: {},
    col: { span: 6 },
  },
];
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { adddetail, axTable },
  data() {
    //这里存放数据
    return {
      api,
      dialogAdd: false,
      cuurentAreaCode: "",
      cuurentAreaCode1: [],
      gridId: "",
      options: [],
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 120,
      }),
      searchActions: [
        { name: "search", text: "查询" },
        { name: "reset", text: "重置" },
      ],
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "物业名称",
            dataIndex: "propertyName",
            ellipsis: true,
            width: 150,
          },
          {
            title: "评价人",
            dataIndex: "residentName",
            ellipsis: true,
            width: 150,
          },
          {
            title: "评价时间",
            dataIndex: "reviewTime",
            ellipsis: true,
            width: 300,
          },
          {
            title: "是否满意",
            dataIndex: "isSatisfied",
            ellipsis: false,
            width: 250,
          },
          {
            title: "评分",
            dataIndex: "core",
            ellipsis: true,
            width: 300,
          },
        ],
        true,
        {
          width: 250,
          actions: [
            {
              props: (record) => {
                return {
                  text: "详情",
                  type: "#2A5CFF",
                  name: "detail",
                  link: true,
                  popDisabled: true,
                };
              },
            },
          ],
        }
      ),
      dataSourceParams: {},
      visible: false,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        //详情
        case "detail":
          this.$refs.detail.openModal(record);
          break;
      }
    },

    //新增刷新列表数据
    refreshTable() {
      this.$refs.roleTable.getDataSource();
    },
  },
  //生命周期 - 创建完成(可以访问当前this实例)
  created() {},
  //生命周期 - 挂载完成(可以访问DOM元素)
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能,这个函数会触发
};
</script>
<style lang='less' scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
.grey {
  background-color: #e6e9ee;
  color: #2d3138;
  border: #e6e9ee;
  border-radius: 4px;
}
.green {
  background-color: #ebf9e6;
  color: #67c23a;
  border-radius: 4px;
  border: #ebf9e6;
}
</style>