<template>
  <el-dialog
    title="查看详情"
    :visible.sync="detailShow"
    width="50%"
     @close="closeDialog"
    :destroy-on-close="true"
    :close-on-click-modal="false"
  >
    <a-descriptions
      style="
        word-break: break-word;
        word-wrap: break-word;
        white-space: pre-wrap;
      "
      title=""
      layout="vertical"
    >
      <a-descriptions-item label="物业名称">{{
        eventDetails.propertyName
      }}</a-descriptions-item>
      <a-descriptions-item label="物业负责人">{{
        eventDetails.head
      }}</a-descriptions-item>
      <a-descriptions-item label="物业联系电话">{{
        eventDetails.phone
      }}</a-descriptions-item>
      <a-descriptions-item label="评价人">{{
        eventDetails.residentName
      }}</a-descriptions-item>
      <a-descriptions-item label="是否满意">
        {{ eventDetails.isSatisfied === 1 ? "不满意" : "满意" }}
      </a-descriptions-item>
      <a-descriptions-item label="评分">{{
        eventDetails.core
      }}</a-descriptions-item>
      <a-descriptions-item label="评价内容">{{
        eventDetails.comment
      }}</a-descriptions-item>
    </a-descriptions>
    <!-- <div style="display: flex; justify-content: flex-end">
      <a-button type="primary" @click="onSubmit">保存</a-button>
      <a-button style="margin-left: 20px" @click="closeDialog">取消</a-button>
    </div> -->
  </el-dialog>
</template>
<script>
import api from "./api";

export default {
  data() {
    return {
      detailShow: false,
      eventDetails: {
        propertyName: "",
        residentName: "",
        registrationTime: "",
        phone: "",
        comment: "",
        location: "",
        isSatisfied: "",
        core: "",
        head: "",
      },
    };
  },
  methods: {
    // 打开弹窗
    openModal(record = {}) {
      this.detailShow = true;
      this.fetchData(record.id);
    },
    // 获取数据
    async fetchData(id) {
      try {
        const res = await api.getById(id);
        if (res.success && res.data) {
          this.eventDetails = res.data;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    // 提交表单
    onSubmit() {
      this.refreshTable();
      this.closeDialog();
    },
    // 关闭弹窗
    closeDialog() {
      this.detailShow = false;
      this.refreshTable();
    },
    // 刷新表格
    refreshTable() {
      this.$emit("refreshTable");
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
/deep/.el-dialog__title {
  color: #2d3138;
}
/deep/.ant-descriptions-view {
  padding-left: 1%;
}
/deep/.ant-descriptions-title {
  font-size: 0.08rem;
  border-left: 4px solid #2a5cff;
  padding-left: 0.5%;
  line-height: 100%;
}

/deep/.ant-descriptions-item-label {
  color: #7d8da6 !important;
  // padding-left: 4px;
}

/deep/.ant-descriptions-item-content {
  color: #2d3138;
}
</style>