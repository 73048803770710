import { post, get, downFile } from 'common/request'

export default {
//根据ID查询
getById: id => get(`/scPropertyReveiws/getById?id=${id}`),

//分页查询
list: args => get('/scPropertyReveiws/list', args),


}
